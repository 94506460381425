import { useEffect, useState } from 'react';
import Api from 'shared/backApi';

export const useYoutube = link => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchYoutubeData = async () => {
      try {
        const response = await Api.getYoutubeData(link);
        setData(response.data[0].videos);
      } catch (e) {
        return;
      }
    };
    fetchYoutubeData();
  }, []);
  return data;
};
