import React from 'react';
import { Text } from '@chakra-ui/react';

/**
 * Компонент `UnderlinedText` — это обёртка над `Text`, которая возвращает текст с анимацией плавного подчёркивания слева направо при наведении.
 * Если передан проп `trigger`, подчёркивание будет видно изначально.
 *
 * param {React.ReactNode} `children` - Текст, который будет отображаться.
 *
 * param {boolean} `trigger`=false - Если `true`, подчёркивание будет отображаться сразу.
 *
 * param `...props` - Любые другие пропсы, поддерживаемые `Text` из Chakra UI
 *
 * @example
 * // Обычное использование с анимацией подчёркивания при наведении
 * <UnderlinedText>Наведи на меня</UnderlinedText>
 *
 * // Подчёркивание отображается сразу
 * <UnderlinedText trigger={true}>Я уже подчёркнут</UnderlinedText>
 */
export default function UnderlinedText({ children, trigger = false, ...props }) {
  return (
    <Text
      pos="relative"
      cursor="pointer"
      _after={{
        content: `''`,
        w: trigger ? 'full' : 0,
        h: '1.6px',
        pos: 'absolute',
        bottom: '-0.1em',
        left: 0,
        bgColor: 'bg.black',
        transition: 'width 0.3s ease',
      }}
      _hover={{
        _after: {
          w: 'full',
        },
      }}
      {...props}
    >
      {children}
    </Text>
  );
}
