import React from 'react';
import { VStack, Text } from '@chakra-ui/react';

import SchevronIcon from 'shared/SchevronIcon';
import Tag from 'shared/Tag';

export default function ExpertiseCard({
  src,
  title,
  text = null,
  tag = null,
  iconProps = null,
  titleProps = null,
  textProps = null,
  textContainerProps = null,
  ...props
}) {
  return (
    <VStack
      pos="relative"
      justify="end"
      boxSize={{ desktop: '600px', laptop: '440px', tablet: '320px', mobile: '320px' }}
      w={{ laptop: '600px', mobile: '100%' }}
      p={{ laptop: '0 48px 48px 48px', mobile: '0 24px 24px 24px' }}
      borderRadius={{ laptop: '48px', tablet: '40px', mobile: '24px' }}
      {...props}
    >
      <SchevronIcon
        src={src}
        pos="absolute"
        top="12px"
        left="12px"
        p={{ laptop: '20px', tablet: '15px', mobile: '10px' }}
        boxSizing="border-box"
        bgColor="bg.white"
        boxSize={{ laptop: '80px', tablet: '54px', mobile: '44px' }}
        {...iconProps}
      />
      {tag && (
        <Tag p="8px 12px" textStyle="h4" fontSize="16px" pos="absolute" top="12px" right="12px">
          {tag}
        </Tag>
      )}
      <VStack
        w="full"
        align="start"
        gap={{ laptop: '20px', mobile: '16px' }}
        fontWeight="500"
        {...textContainerProps}
      >
        <Text as="h3" {...titleProps}>
          {title}
        </Text>
        {text && (
          <Text as="p" textStyle={{ desktop: 'h1', laptop: 'h2', mobile: 'h1' }} {...textProps}>
            {text}
          </Text>
        )}
      </VStack>
    </VStack>
  );
}
