import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { VStack } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import React, { useRef, useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import CulturalCodeHeader from 'widgets/CulturalCodeHeader';
import CulturalCodeFooter from 'widgets/CulturalCodeFooter';
import Footer from 'widgets/Footer';
import Header from 'widgets/Header';
import Cookies from 'features/Cookies';
import { RadioProvider } from 'shared/RadioContext';
import AnimatedCircles from 'shared/AnimatedCircles';
import { throttle } from 'shared/lib/throttle';
import Head from 'shared/Head';

import data from './data.json';
import { overlapOffset } from 'constants/pageLayout';

gsap.registerPlugin(ScrollTrigger, useGSAP);

ScrollTrigger.config({
  ignoreMobileResize: true,
});

export default function PageLayout({ children }) {
  const { setIsLight, setIsOverlapsFooter } = useBoundStore(headerSelector);
  const headerRef = useRef(null);
  const componentRef = useRef(null);
  const pathname = useLocation().pathname;
  const isCulturalCodePage = pathname === '/cultural-code';

  const checkHeaderAndFooterOverlaped = () => {
    if (headerRef.current && componentRef.current) {
      const a = headerRef.current.getBoundingClientRect();
      const b = componentRef.current.getBoundingClientRect();
      if (a.top + overlapOffset <= b.top + b.height && a.top + a.height > b.top + overlapOffset) {
        setIsLight(true);
        setIsOverlapsFooter(true);
      } else {
        setIsLight(false);
        setIsOverlapsFooter(false);
      }
    }
  };

  useEffect(() => {
    const throttledCheckFn = throttle(checkHeaderAndFooterOverlaped);
    window.addEventListener('scroll', throttledCheckFn);
    throttledCheckFn();

    return () => window.removeEventListener('scroll', throttledCheckFn);
  }, []);

  return (
    <>
      <Head {...data.head} />
      <VStack
        w="full"
        minH="100vh"
        boxSizing="border-box"
        pos="relative"
        zIndex="1"
        gap="0"
        overflow="clip"
      >
        {!isCulturalCodePage && (
          <AnimatedCircles
            top={{ tablet: '350px', mobile: '400px' }}
            left={{ laptop: '35%', tablet: '25%', mobile: '35%' }}
            boxSize={{ laptop: '200px', tablet: '50px' }}
          />
        )}
        <RadioProvider>
          {isCulturalCodePage ? <CulturalCodeHeader /> : <Header ref={headerRef} />}
          {children}
          {isCulturalCodePage ? <CulturalCodeFooter /> : <Footer ref={componentRef} />}
          <Cookies />
        </RadioProvider>
      </VStack>
    </>
  );
}
