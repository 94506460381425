import React from 'react';
import { HStack, Text } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { IconButton } from 'shared/IconButton';

export default function HeaderGoBackButton() {
  const { updateHeaderMenuData } = useBoundStore(headerSelector);

  return (
    <HStack gap="8px" cursor="pointer" onClick={() => updateHeaderMenuData('reset')}>
      <IconButton
        direction="left"
        disableHover
        border="none"
        boxSize={{ mobile: '32px', tablet: '48px' }}
        iconProps={{ boxSize: { mobile: '24px', tablet: '32px' } }}
      />
      <Text textStyle="header.h4">Назад</Text>
    </HStack>
  );
}
