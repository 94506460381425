import React, { memo } from 'react';
import { VStack } from '@chakra-ui/react';

import { HEADER_HEIGHTS } from 'constants/global';

const Wrapper = ({ children, ...props }) => (
  <VStack
    w="full"
    maxW={{ desktop: '1440px', mobile: 'full' }}
    boxSizing="border-box"
    gap={{ laptop: '180px', tablet: '120px', mobile: '80px' }}
    mt={HEADER_HEIGHTS}
    pt={{ laptop: '147px', tablet: '160px', mobile: '148px' }}
    px={{ laptop: '40px', tablet: '20px', mobile: '16px' }}
    pb={{ laptop: '180px', tablet: '120px', mobile: '80px' }}
    overflowX="hidden"
    pos="relative"
    zIndex="10"
    {...props}
  >
    {children}
  </VStack>
);

export default memo(Wrapper);
