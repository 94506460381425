import React, { memo } from 'react';
import { HStack } from '@chakra-ui/react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import PlayPauseButton from 'shared/PlayPauseButton';
import AudioProgress from 'shared/AudioProgress';
import { ym } from 'shared/lib/ym';

const AstonRadio = props => {
  const { togglePlayPause, playing } = useGlobalAudioPlayer();

  const handlePlayPause = () => {
    ym('radio_play_button_click');
    togglePlayPause();
  };

  return (
    <HStack
      minH={{ mg: '62px' }}
      spacing="12px"
      align="center"
      whiteSpace="nowrap"
      p={{ mobile: '8px 12px', tablet: '13px 20px', mg: 'unset' }}
      borderRadius={{ mobile: '43px', tablet: '80px', mg: 'unset' }}
      {...props}
    >
      <PlayPauseButton isPlaying={playing} onPlayPauseClick={handlePlayPause} />
      <AudioProgress />
    </HStack>
  );
};

export default memo(AstonRadio);
