import React from 'react';
import { Img } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';

/**
 * Компонент `ThemedImage` — это обёртка над `NextImage`, которая изменяет фильтр изображения
 * в зависимости от текущей темы (светлая или тёмная).
 *
 * param {string} `src` - Путь к изображению.
 *
 * param `...props` - Дополнительные пропсы, передаваемые в `NextImage`.
 *
 * @example
 * // Использование компонента с динамическим изменением цвета
 * <ThemedImage w="100px" h="100px" src="/logo.png" alt="Логотип" />
 */
export default function ThemedImage({ src, ...props }) {
  const isLight = useBoundStore(({ isLight }) => isLight);

  return (
    <Img
      src={src}
      filter={isLight ? `brightness(0) invert(1)` : 'none'}
      transition="filter .2s linear"
      {...props}
    />
  );
}
