import React from 'react';
import { motion } from 'framer-motion';
import { Img, VStack } from '@chakra-ui/react';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import NavItem from 'shared/CulturalCodeNavItem';
import { usePageScrollLock } from 'shared/hooks';

import illustration from 'assets/CulturalCode/bgCircle.svg';
import { headerLinks } from 'constants/CulturalCode';
import { variants } from 'constants/header';

export default function CulturalCodeNavigation(props) {
  const { isMenuOpen, updateHeaderMenuState } = useBoundStore(headerSelector);
  usePageScrollLock(isMenuOpen);

  return (
    <VStack
      as={motion.nav}
      variants={variants}
      userSelect="none"
      w="100dvw"
      h="calc(100vh - 71px)"
      pos="fixed"
      top="71px"
      right="0"
      gap="24px"
      align="start"
      justifyContent="space-between"
      bgColor="culturalCode.bg"
      overflow="hidden"
      zIndex="1050"
      {...props}
    >
      <Img
        src={illustration}
        alt="Иллюстрация"
        objectFit="contain"
        pos="absolute"
        bottom={{ mobile: '-178px', tablet: '-145px', laptop: '-272px' }}
        right={{ mobile: '-185px', tablet: '-238px', laptop: '-96px', desktop: '136px' }}
        boxSize={{ mobile: '503px', tablet: '823px', laptop: '1065px' }}
        opacity="0.3"
      />
      <VStack
        w="full"
        pos="relative"
        align="start"
        p={{ mobile: '40px 35px 40px 0', tablet: '120px 40px 120px 0px' }}
        spacing={{ mobile: '40px', tablet: '60px', laptop: '80px' }}
        overflow="auto"
        zIndex="10"
      >
        {headerLinks.map(linkData => (
          <NavItem
            key={linkData.text}
            onClick={() => updateHeaderMenuState('toggle')}
            {...linkData}
          />
        ))}
      </VStack>
    </VStack>
  );
}
