import {
  VStack,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  HStack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import { useBoundStore } from 'app/store';
import { headerSelector } from 'app/selectors';
import { msToTime } from 'shared/lib/msToTime';
import RadioLogo from 'shared/RadioLogo';

import { HEADER_BREAKPOINT } from 'constants/global';

export default function AudioProgress() {
  const [progress, setProgress] = useState(0);
  const [isCaptured, setIsCapture] = useState(false);
  const { duration, getPosition, seek, playing } = useGlobalAudioPlayer();
  const { isLight } = useBoundStore(headerSelector);

  const cachedDur = useMemo(() => msToTime(duration ?? 0), [duration]);

  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        isCaptured || setProgress(getPosition());
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isCaptured, playing]);

  return (
    <VStack
      w="full"
      minW={{ mg: '160px' }}
      align="start"
      spacing="10px"
      color={isLight && 'text.white'}
    >
      <RadioLogo />

      {playing && (
        <VStack w="full" spacing="0" hideBelow={HEADER_BREAKPOINT}>
          <Slider
            w="full"
            step={1}
            alignItems="center"
            focusThumbOnChange={false}
            value={progress}
            max={duration}
            onChangeStart={() => setIsCapture(true)}
            onChange={setProgress}
            onChangeEnd={val => {
              setIsCapture(false);
              seek(val);
            }}
          >
            <SliderTrack bgColor="#E3F9A4" borderRadius="full" h="1.6px" cursor="pointer">
              <SliderFilledTrack
                bgColor={`bg.${isLight ? 'white' : 'black'}`}
                h="1.6px"
                borderRadius="full"
              />
            </SliderTrack>
            <SliderThumb
              boxSize="6px"
              bgColor="bg.accent"
              borderRadius="full"
              cursor="pointer"
              top="50%"
            />
          </Slider>
          <HStack w="full" fontSize="12px" fontWeight="400" justify="space-between">
            <Text>{msToTime(progress)}</Text>
            <Text>{cachedDur}</Text>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
