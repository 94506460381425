import { useCallback, useEffect } from 'react';

export const useSwiperControls = (swiper, slidesPerView = false) => {
  const slideNext = useCallback(() => {
    if (swiper.isEnd) return swiper.slideTo(0);
    if (slidesPerView) {
      swiper.slideTo(swiper.activeIndex + swiper.slidesPerViewDynamic());
    } else {
      swiper.slideNext();
    }
  }, [swiper]);
  const slidePrev = useCallback(
    () =>
      slidesPerView
        ? swiper.slideTo(swiper.activeIndex - swiper.slidesPerViewDynamic())
        : swiper.slidePrev(),
    [swiper],
  );

  useEffect(() => {
    swiper && !Object.prototype.hasOwnProperty.call(swiper, 'destroyed') && swiper.slideTo(0);
  });

  return { slideNext, slidePrev };
};
