exports.getBuildEnvironment = function () {
  return process.env.GATSBY_BUILD_ENV;
};

exports.getIsSsr = function () {
  return process.env.NODE_ENV === 'production';
};

exports.getApiUrl = function () {
  return process.env.GATSBY_API_URL || '';
};

exports.getAssetUrl = function () {
  return process.env.GATSBY_STATIC_URL || '';
};

exports.getCareerLinks = function () {
  const CAREER_LINK = process.env.GATSBY_CAREER_URL || '';
  const VACANCY_LINK = process.env.GATSBY_VACANCY_URL || '';
  const TRAINEE_LINK = process.env.GATSBY_TRAINEE_URL || '';
  const COURSES_LINK = process.env.GATSBY_COURSES_URL || '';

  return {
    CAREER_LINK,
    VACANCY_LINK,
    TRAINEE_LINK,
    COURSES_LINK,
  };
};
